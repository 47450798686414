import React from 'react'
import { CMSWrapper } from '../../components/cmsWrapper'
import { Link } from 'gatsby'
import { Navigation } from '../../common/navigation'
import { GlobalHelmet } from '../../common/seo/globalHelmet'
import { Footer } from '../../common/footer'

export default function DenWechselHabeIchNieBereut() {
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Den Wechsel habe ich nie bereut" />
      <main>
        <Navigation />

        <header className="bg-img-yellow divider-left">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/daniela-jung-1.jpg"
                  alt="Portrait"
                  title="Daniela Jung"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-5 offset-md-0 col-10 offset-1">
              <h1>
                <q>Den Wechsel habe ich nie bereut. Man muss ihn nur wagen.</q>
              </h1>
              <h4>
                Daniela Junge, 33, ist examinierte Altenpflegerin. Sie arbeitet
                seit 2012 in der pflegerischen Patientenversorgung im UKE und
                ist seit Kurzem stellvertretende Stationsleitung im Zentrum für
                Psychosoziale Medizin.
              </h4>
            </div>
          </div>
        </header>

        <section className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12">
              <p>
                „Meine Mutter, Schwester, Oma, Tante und Cousine, also gefühlt
                die Hälfte meiner Familie, war oder ist in der Pflege tätig. Da
                war für mich die Richtung klar. Ich habe nach der Realschule den
                Beruf der Altenpflege gewählt und nach dem Examen drei Jahre im
                Alten- und Pflegeheim sowie in der ambulanten Pflege gearbeitet.
                Was mir besonders gefiel: Man arbeitet direkt am Menschen und
                trägt dazu bei, dass es ihm gut geht. Eine schöne und sehr
                erfüllende Aufgabe.
              </p>
              <p>
                Aber irgendwann schlich sich dieses Gefühl ein: Da fehlt noch
                was, da geht noch mehr. Ich war neugierig, wollte neue Wege der
                Pflege kennenlernen, Erfahrungen sammeln, ein tieferes
                Verständnis für Zusammenhänge gewinnen und vor allem: Meinen
                eigenen Horizont erweitern. Und wo, bitte schön, kann man das
                besser als im UKE, in einem Krankenhaus der Maximalversorgung,
                wo Forschung und Lehre mit einbezogen werden? Ich war mir
                sicher, dass ich dort die maximalen Möglichkeiten in der
                beruflichen Weiterentwicklung erfahren kann. Demzufolge habe ich
                eine Initiativbewerbung an das UKE geschrieben und kurz darauf
                eine Stelle in der Gerontopsychiatrie erhalten.
              </p>
              <p>
                Das war 2012 und ein Volltreffer, denn ich konnte an meine
                Erfahrungen im Alten- und Pflegeheim anknüpfen: Neben
                Demenzerkrankungen sind Depressionen die häufigsten psychischen
                Erkrankungen im Alter.
              </p>
              <p>
                Anfangs war ich unsicher: Bin ich gut genug? Kann ich als
                Altenpflegerin mit den Gesundheits- und Krankenpflegekräften
                mithalten? Aber das legt sich schnell. Ich habe spannende
                Fortbildungen besucht: Soziales Kompetenztraining, Akupunktur,
                Progressive Muskelentspannung etc. Das konnte ich unmittelbar
                auf der Station anwenden und tue es auch heute noch.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 col-12 my-5 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/daniela-jung-2.jpg"
                  alt="Portrait"
                  title="Daniela Jung"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Im Rahmen eines internen Jobtauschs habe ich dann mal sechs
                Wochen auf der{' '}
                <Link to="/station/station-tagesklinik-fuer-affektive-stoerungen-p3">
                  P3, der Station und Tagesklinik für Erwachsene mit
                  Depressionen
                </Link>
                , gearbeitet – und war so angetan, dass ich mich vor zwei Jahren
                auf eine Stelle dort bewarb und sie bekam. Die Patientinnen und
                Patienten verbringen bis zu zwei Monate auf unserer Station.
                Ähnlich wie in der Langzeitpflege kann man auf solch einer
                Station eine Beziehung zu den Patienten und Patientinnen
                aufbauen, was mir besonders wichtig ist ..
              </p>
              <p>
                Seit Kurzem bin ich die stellvertretende Stationsleitung der
                Station P3. Anderthalb Jahre hatte ich mich parallel zur Arbeit
                weiterqualifiziert und konnte in der Akademie für Bildung und
                Karriere einen Leitungskurs für Gesundheitsfachberufe besuchen.
                In dieser Weiterbildung habe ich das Handwerkszeug für meine
                heutige Leitungsaufgabe gelernt: Wie stärkt man die Motivation
                der Mitarbeitenden? Wie hält man ein Team zusammen, wie bringt
                man es voran?
              </p>
              <p>
                Nun arbeite ich in der Pflege und im Management des Teams. Ein
                Spagat, der mir Freude bereitet und Herausforderung bietet. Den
                Wechsel habe ich nie bereut. Man muss ihn nur wagen.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
